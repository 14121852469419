import 'unfonts.css';
import 'virtual:svg-icons-register';
import '@/css/app.pcss';
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'leaflet/dist/leaflet.css';
import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect';
import collapse from '@alpinejs/collapse';
import resize from '@aerni/alpine-resize';
import validate from "@colinaut/alpinejs-plugin-simple-validate";

import uniqueRandomArray from 'unique-random-array';
import {ofetch} from "ofetch";

Alpine.plugin(intersect);
Alpine.plugin(collapse);
Alpine.plugin(resize);
Alpine.plugin(validate);

window['uniqueRandomArray'] = uniqueRandomArray;

const main = async () => {
    const {default: barba} = await import('@barba/core');
    const {default: dayjs} = await import('dayjs');
    const {default: localizedFormat} = await import('dayjs/plugin/localizedFormat');
    const {default: relativeTime} = await import('dayjs/plugin/relativeTime');
    dayjs.extend(localizedFormat);
    dayjs.extend(relativeTime);
    await import('dayjs/locale/nb');
    dayjs.locale('nb');

    await import('lazysizes/plugins/bgset/ls.bgset');
    await import('lazysizes/plugins/unveilhooks/ls.unveilhooks');
    await import('lazysizes/plugins/noscript/ls.noscript');
    await import('lazysizes');
    const {default: ScrollOut} = await import('scroll-out');

    return {dayjs, barba, ScrollOut};
}

const getHarmony = (el = document) => {
    let klasse = Array.from(el.querySelector('[class^="harmony-"]')?.classList || []).filter(item => item.startsWith('harmony-'))[0] || null;
    if (klasse) {
        // console.log(klasse);
        klasse = klasse.replace('harmony-', '').replace('-inverted', '');
        return klasse;
    }
    return null;
}
const setTheme = (theme: string, interted: boolean = false) => {
    switch (theme) {
        case 'gray':
        default:
            return interted ? 'harmony-gray-inverted' : 'harmony-gray';
            break;
        case 'green':
            return interted ? 'harmony-green-inverted' : 'harmony-green';
            break;
        case 'blue':
            return interted ? 'harmony-blue-inverted' : 'harmony-blue';
            break;
        case 'purple':
            return interted ? 'harmony-purple-inverted' : 'harmony-purple';
            break;
        case 'orange':
            return interted ? 'harmony-orange-inverted' : 'harmony-orange';
            break;
    }
}

window['setTheme'] = setTheme;

const isExternalURL = (url: string) => new URL(url, location.origin).origin !== location.origin;

const removeClassByPrefix = (node, prefix) => {
    let regx = new RegExp('\\b' + prefix + '[^ ]*[ ]?\\b', 'g');
    node.className = node.className.replace(regx, '');
    return node;
}

const cfDecodeEmail = (encodedString) => {
    let email = "", r = parseInt(encodedString.substr(0, 2), 16), n, i;
    for (n = 2; encodedString.length - n; n += 2) {
        i = parseInt(encodedString.substr(n, 2), 16) ^ r;
        email += String.fromCharCode(i);
    }
    return email;
}


Array.prototype.random = function () {
    return this[Math.floor((Math.random() * this.length))];
}
// Execute async function
main().then(modules => {
    window["dayjs"] = modules.dayjs;
    window["app"] = {
        defaults() {
            return {
                isReady: false,
                isDev: document.body.classList.contains('dev'),
                isLoading: false,
                showGrid: localStorage.getItem('showGrid') === 'true',
                popupSkjema: false,
                header: {
                    inverted: false,
                    showMeny: false,
                    showBg: false,
                    scroller: null
                },
                user: {
                    isGuest: true
                },
                csrf: null,
                editId: null,
                admin: {
                    show: localStorage.getItem('showAdmin') === 'true',
                    lenke: null,
                    visErrorSkjema: false,
                    url: '/admin',
                },
                theme: {
                    color: null,
                    light: false
                },
                init: async function () {
                    await ofetch('/admin/actions/users/session-info', {headers: {'accept': 'application/json'}})
                        .then(json => {
                            this.user = json;
                            this.csrf = json.csrfTokenValue;
                            if (!this.user.isGuest) {
                                try {
                                    // @ts-ignore
                                    Sentry.setUser({
                                        username: this.user.username,
                                        email: this.user.email
                                    });
                                } catch (error) {
                                    console.warn(error);
                                }
                                this.adminbar();
                            }
                        }).catch((error) => {
                            console.error('Error:', error);
                        });

                    this.$watch('editId', (value) => {
                        // console.log('editId', value)
                        this.hentEdit(value);
                    });

                    this.$watch('admin.show', (value) => {
                        localStorage.setItem('showAdmin', value)
                    })

                    if (this.isDev) {
                        this.$watch('showGrid', (value) => {
                            localStorage.setItem('showGrid', value)
                        })
                    }

                    removeClassByPrefix(document.body, 'bg-');
                    this.felles(true);
                    modules.barba.init({
                        timeout: 10000,
                        prefetchIgnore: true,
                        transitions: [{
                            once: () => {

                            },
                            beforeLeave: () => {
                                const formCapture = document.querySelector('#formCapture');
                                if (formCapture) {
                                    console.log('remove', formCapture);
                                    formCapture.remove();
                                }
                                this.header.scroller.teardown();
                            },
                            leave: () => {
                                console.log('leave');
                                this.isLoading = true;
                            },
                            enter: (data) => {
                                console.log('enter');
                                // console.log(data);
                                const html = new DOMParser().parseFromString(data.next.html, "text/html");
                                document.title = html.title;
                                document.querySelector('nav').innerHTML = html.querySelector('nav').innerHTML;
                            },
                            afterEnter: () => {
                                window.scrollTo({top: 0});
                                this.$nextTick(() => {
                                    this.isLoading = false;
                                })
                            },
                            after: () => {
                                console.log('after');
                                this.felles();
                            }
                        }]
                    });
                    this.$nextTick(() => {
                        this.isReady = true;
                    });
                },
                setBodyClass(klasse = 'gray', light = false) {
                    if (!klasse) return null;
                    return `bg-${klasse}-${light ? '100' : (klasse == 'gray' ? '900' : '1000')}`;
                },
                async adminbar() {
                    const {default: adminbar} = await import('@/template/adminbar.html?raw');
                    var elem = document.createElement('div');
                    elem.innerHTML = adminbar;
                    document.body.appendChild(elem);
                },
                async hentEdit(id = null) {
                    if (!this.csrf || this.user.isGuest || id == null) {
                        return false;
                    }
                    const {data: lenke} = await ofetch('/api/edit?ids=' + id);
                    this.admin.lenke = lenke[0] || null;
                },
                felles(first = false) {
                    this.theme.color = getHarmony(document.querySelector('main')) || 'gray';
                    this.theme.light = document.querySelector('[data-themeLight]');

                    this.header.inverted = document.querySelector('[data-headerInverted]');
                    if (!this.user.isGuest) {
                        this.editId = document.querySelector('[data-editid]')?.dataset.editid;
                    }
                    if (!first) {
                        this.header.showMeny = false;
                    }

                    document.querySelectorAll(".button-cta:not(.copied), .button-cta-icon:not(.copied)").forEach((elm) => {
                        let wrapper = document.createElement('div'),
                            inline: HTMLSpanElement = document.createElement('span');
                        wrapper.classList.add('relative', 'flex', 'flex-col', 'whitespace-nowrap', 'pointer-events-none');
                        inline.classList.add('placement');
                        inline.innerHTML = elm.innerHTML;
                        elm.innerHTML = '';
                        let inlineCopy = <HTMLSpanElement>inline.cloneNode(true);
                        inlineCopy.classList.add('absolute', 'top-full', 'left-0');
                        inlineCopy.setAttribute("aria-hidden", "true");
                        wrapper.appendChild(inline);
                        wrapper.appendChild(inlineCopy);
                        elm.appendChild(wrapper);
                        elm.classList.add('copied');
                        // console.log(elm)
                    });

                    document.querySelectorAll("a[href='#soknad']").forEach((elm) => {
                        elm.addEventListener('click', (e) => {
                            e.preventDefault();
                            this.popupSkjema = true;
                        })
                    });

                    document.querySelectorAll(".__cf_email__").forEach((elm) => {
                        let parent = elm.parentElement,
                            email = cfDecodeEmail(elm.dataset.cfemail);
                        if (parent) {
                            parent.href = 'mailto:' + email;
                            parent.innerText = email;
                        }
                    })

                    this.$nextTick(() => {
                        let menuOffset = 1;
                        const offetEl = document.querySelector('[data-menuoffset]');
                        const h1 = document.querySelector('h1');
                        const header = document.querySelector('header');
                        if (offetEl) {
                            menuOffset = offetEl.clientHeight - header.clientHeight;
                        } else if (h1) {
                            menuOffset = h1.offsetTop - header.clientHeight;
                        }

                        // console.log(offetEl, h1, menuOffset)
                        this.header.scroller = modules.ScrollOut({
                            targets: "header",
                            offset: menuOffset,
                            onShown: () => {
                                this.header.showBg = true;
                                /* Triggered when an element is shown */
                            },
                            onHidden: () => {
                                this.header.showBg = false;
                                /* Triggered when an element is hidden */
                            }
                        });
                    });
                },
                videoLoad() {
                    return this.isReady && !this.isLoading;
                },
                toggleGrid() {
                    this.showGrid = !this.showGrid;
                }
            }
        },
        animateText() {
            return {
                init() {
                    this.$nextTick(() => {
                        let i = 0;
                        this.$root.querySelectorAll('.anim').forEach((elm) => {
                            i++;
                            elm.classList.add('transition-colors', 'duration-500');
                            elm.classList.remove('anim');
                            setTimeout(() => {
                                elm.classList.add('theme-text-primary');
                            }, i * 750)

                        })
                    });
                }
            }
        },
        smoothScrollTo(selector = '') {
            const el = document.querySelector(selector);
            if (el) {
                const to = el.offsetTop - document.querySelector('header').clientHeight;
                window.scrollTo({top: to, behavior: 'smooth'});
            }
        },
        getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
        },
        getRandomScale() {
            const scales = ['scale-150', 'scale-50', 'scale-100', 'scale-125'],
                randomIndex = Math.floor((Math.random() * scales.length));
            return scales[randomIndex];
        },
        getAktuelleSaker(options = []) {
            return {
                saker: [],
                meta: [],
                options: options,
                kategorier: [],
                kategori: window.location.hash.replace('#', '') || '',
                typer: [],
                filter: {
                    kategori: '',
                    typer: []
                },
                laster: false,
                async hent() {
                    this.laster = true;
                    const data = await ofetch('/api/aktuelle-saker').catch(() => null);
                    this.laster = false;
                    // console.log(data);
                    if (data) {
                        this.meta = data.meta;
                        const saker = data.data;
                        let kategorier = saker.map(item => item.tag).map(JSON.stringify).reverse().filter((e, i, a) => a.indexOf(e, i + 1) === -1).reverse().map(JSON.parse);
                        kategorier = kategorier.map((kat) => {
                            kat.antall = saker.filter(item => item.tag.value == kat.value).length;
                            return kat;
                        }).sort((a, b) => (a.label > b.label) ? 1 : -1)
                        let typer = saker.map(item => item.type).map(JSON.stringify).reverse().filter((e, i, a) => a.indexOf(e, i + 1) === -1).reverse().map(JSON.parse)
                            .sort((a, b) => (a.label > b.label) ? 1 : -1);
                        this.kategorier = kategorier;
                        this.typer = typer;
                        this.saker = saker;
                        if (window.location.hash) {
                            const hashes = window.location.hash.replace('#', '').split('-');
                            this.filter = {
                                kategori: kategorier.find(item => hashes.includes(item.value))?.value ?? '',
                                typer: typer.filter(item => hashes.includes(item.value)).map(item => item.value) ?? []
                            }
                        }
                        this.$watch('filter', (value) => {
                            const values = Object.entries(value).map(item => item[1].toString().split(',').join('-')).filter((val) => !!val);
                            // console.log(value, values)
                            window.location.hash = values.join('-');
                        })
                    }
                },
                katBg() {
                    return this.kategorier.find(item => item.value == this.kategori)?.themeValue || null
                },
                sortert() {
                    const hasKat = !!this.filter.kategori;
                    const hasType = this.filter.typer.length > 0;
                    if (hasKat || hasType) {
                        this.theme.color = this.katBg();
                        return this.saker.filter(item => {
                            const katMatch = hasKat ? item.tag.value == this.filter.kategori : true;
                            const typeMatch = hasType ? this.filter.typer.includes(item.type.value) : true;
                            return katMatch && typeMatch;
                        });
                    }
                    return this.saker;
                }
            }
        },
        getProsjekter() {
            return {
                prosjekter: [],
                meta: [],
                kategorier: [],
                kategori: '',
                laster: false,
                async hent() {
                    this.laster = true;
                    const data = await ofetch('/api/prosjekter').catch(() => null);
                    this.laster = false;
                    // console.log(data);
                    if (data) {
                        this.meta = data.meta;
                        let prosjekter = data.data;
                        let kategorier = prosjekter.map(item => item.category).reduce((cats, cat) => cats.concat(cat), []).map(JSON.stringify).reverse().filter((e, i, a) => a.indexOf(e, i + 1) === -1).reverse().map(JSON.parse);
                        kategorier = kategorier.map((kat) => {
                            console.log(kat)
                            kat.antall = prosjekter.filter(item => item.category.map(kat => kat.value).includes(kat.value)).length;
                            return kat;
                        }).sort((a, b) => (a.label > b.label) ? 1 : -1)
                        this.kategorier = kategorier;
                        this.prosjekter = prosjekter;
                    }
                },
                sortert() {
                    let resultat = this.prosjekter;
                    if (this.kategori != '') {
                        resultat = resultat.filter((prosjekt) => {
                            return prosjekt.category.map(kat => kat.value).includes(this.kategori);
                        })
                    }
                    return resultat || [];
                }
            }
        },
        getStillinger() {
            return {
                stillinger: [],
                meta: [],
                kategorier: {
                    job: [],
                    office: [],
                },
                valgt: {
                    job: 'alle',
                    office: 'alle',
                },
                laster: false,
                async hent() {
                    this.laster = true;
                    const data = await ofetch('/api/stillinger').catch(() => null);
                    this.laster = false;
                    // console.log(data);
                    if (data) {
                        this.meta = data.meta;
                        let stillinger = data.data;
                        this.kategorier.job = stillinger.map(item => item.job).filter(item => item.value).map(JSON.stringify).reverse().filter((e, i, a) => a.indexOf(e, i + 1) === -1).reverse().map(JSON.parse).sort((a, b) => (a.label > b.label) ? 1 : -1);
                        this.kategorier.office = stillinger.map(item => item.office).filter(item => item.value).map(JSON.stringify).reverse().filter((e, i, a) => a.indexOf(e, i + 1) === -1).reverse().map(JSON.parse).sort((a, b) => (a.label > b.label) ? 1 : -1);
                        this.stillinger = stillinger;
                    }
                },
                sortert() {
                    let resultat = this.stillinger;
                    if (this.valgt.job != 'alle') {
                        resultat = resultat.filter((stilling) => {
                            return stilling.job.value == this.valgt.job;
                        })
                    }
                    if (this.valgt.office != 'alle') {
                        resultat = resultat.filter((stilling) => {
                            return stilling.office.value == this.valgt.office;
                        })
                    }
                    return resultat || [];
                }
            }
        },
        getKontor() {
            return {
                kontorer: [],
                valgt: null,
                laster: false,
                async hent() {
                    this.laster = true;
                    const data = await ofetch('/api/kontor').catch(() => null);
                    this.laster = false;
                    if (data) {
                        this.kontorer = data.data;
                    }
                },
                kontor() {
                    if (!this.valgt) {
                        return this.kontorer[0];
                    }
                    return this.kontorer.find(item => item.id == this.valgt);
                }
            }
        },
        getAnsatte(sok = '') {
            return {
                ansatte: [],
                meta: [],
                kontor: 'alle',
                kontorer: [],
                sok: sok,
                miniSearch: null,
                laster: false,
                async hent() {
                    this.laster = true;
                    const data = await ofetch('/api/ansatte').catch(() => null);
                    // console.log(this.sok);
                    this.laster = false;
                    if (data) {
                        this.meta = data.meta;
                        this.ansatte = data.data;
                        this.kontorer = this.ansatte.map(item => item.office).filter(item => item.value).map(JSON.stringify).reverse().filter((e, i, a) => a.indexOf(e, i + 1) === -1).reverse().map(JSON.parse);
                        const {default: MiniSearch} = await import('minisearch');
                        let miniSearch = new MiniSearch({
                            fields: ['name', 'job.label', 'office.label', 'email', 'tlf'], // fields to index for full-text search
                            storeFields: ['name', 'job', 'office', 'email', 'tlf'], // fields to return with search results
                            extractField: (document, fieldName) => {
                                // Access nested fields
                                return fieldName.split('.').reduce((doc, key) => doc && doc[key], document)
                            }
                        })
                        miniSearch.addAll(data.data);
                        this.miniSearch = miniSearch;
                    }
                },
                utvalgte(ids = []) {
                    return this.ansatte.filter((ansatt) => {
                        return ids.includes(ansatt.id);
                    }).sort(function (a, b) {
                        return ids.indexOf(a.id) - ids.indexOf(b.id);
                    })
                },
                sortert() {
                    let resultat = this.ansatte;
                    if (this.sok != '') {
                        // console.log(this.miniSearch);
                        resultat = this.miniSearch.search(this.sok, {
                            prefix: true,
                            fuzzy: 0.2
                        });
                    }
                    if (this.kontor != 'alle') {
                        resultat = resultat.filter((ansatt) => {
                            return ansatt.office.value == this.kontor;
                        })
                    }
                    return resultat || [];
                }
            }
        },
        slideshow(options = {}) {
            return {
                locked: false,
                async init() {
                    const {Swiper, Navigation, Keyboard, Scrollbar} = await import('swiper');
                    Swiper.use([Navigation, Keyboard, Scrollbar]);

                    const defaultOptions = {
                        slidesPerView: "auto",
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                            disabledClass: 'disabled',
                            lockClass: 'hidden'
                        },
                        scrollbar: {
                            el: '.swiper-scrollbar',
                            draggable: false,
                            lockClass: 'hidden'
                        }
                    };
                    const swiper = new Swiper(this.$root, {...defaultOptions, ...options});
                    swiper.on('lock', () => {
                        console.log('locked');
                        this.locked = true;
                    });
                    swiper.on('unlock', () => {
                        console.log('unlocked');
                        this.locked = false;
                    });
                }
            }
        },
        initKart() {
            return {
                map: null,
                marker: null,
                async setup(lat = 50, lng = 13, zoom = 15) {
                    const L = await import('leaflet');
                    this.map = L.map(this.$refs.kart, {
                        center: [lat, lng],
                        minZoom: 8,
                        zoom: zoom,
                        maxZoom: 20,
                        scrollWheelZoom: false,
                        zoomControl: true,
                        tap: false
                    });

                    // light_all,
                    // dark_all,
                    // light_nolabels,
                    // light_only_labels,
                    // dark_nolabels,
                    // dark_only_labels,
                    // rastertiles/voyager,
                    // rastertiles/voyager_nolabels,
                    // rastertiles/voyager_only_labels,
                    // rastertiles/voyager_labels_under

                    const style = 'rastertiles/voyager';
                    L.tileLayer('https://{s}.basemaps.cartocdn.com/' + style + '/{z}/{x}/{y}' + (L.Browser.retina ? '@2x.png' : '.png'), {
                        // attribution:'&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attributions">CARTO</a>',
                        maxZoom: 20,
                        minZoom: 0
                    }).addTo(this.map);
                    if (this.kontor) {
                        this.$watch('kontor', (kontor) => {
                            if (kontor.address) {
                                this.changePlassering(kontor.address.lat, kontor.address.lng, kontor.address.zoom);
                            }
                        })
                    } else {
                        this.changePlassering(lat, lng, zoom);
                    }
                },
                changePlassering(lat = null, lng = null, zoom = null) {
                    this.map.setView([lat, lng], zoom);
                    if (this.marker) {
                        this.map.removeLayer(this.marker);
                    }

                    const icon = L.divIcon({
                        html: `<svg class="w-full h-full absolute fill-current"><use href="#icon-location"/></svg>`,
                        className: "",
                        iconSize: [40, 40], // size of the icon
                        iconAnchor: [40 / 2, 40], // point of the icon which will correspond to marker's location
                    });

                    this.marker = L.marker(new L.LatLng(lat, lng), {
                        icon: icon,
                        interactive: false
                    }).addTo(this.map);
                }
            }
        },
        ticker(antall = 3, sec = 5) {
            return {
                init() {
                    this.$nextTick(() => {
                        let ticker = this.$el,
                            list = document.createElement("ul");

                        list.style.cssText = `animation-duration: ${ticker.childElementCount * sec}s`;
                        list.innerHTML = ticker.innerHTML;

                        ticker.innerHTML = '';
                        for (let i = 0; i < antall; i++) {
                            ticker.append(list.cloneNode(true));
                        }
                        setTimeout(() => {
                            ticker.querySelectorAll('img.load').forEach((img) => {
                                const src = img.dataset.src;
                                console.log(src);
                                img.setAttribute("src", src);
                                img.classList.remove('opacity-0');
                            })
                        }, 300);
                    });
                }
            }
        },
        formatBytes(bytes, decimals = 2) {
            if (!+bytes) return '0 Bytes'

            const k = 1024
            const dm = decimals < 0 ? 0 : decimals
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

            const i = Math.floor(Math.log(bytes) / Math.log(k))

            return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
        },
        checkSize() {
            return {
                ratio: 1,
                small: false,
                change() {
                    const width = this.$el.clientWidth,
                        height = this.$el.clientHeight;
                    this.small = width < 520 || height < 420;
                    this.ratio = (height / width).toFixed(1);
                    if (height == width) {
                        this.ratio = 1;
                    }
                    console.log('resize', this.icon, this.ratio);
                }
            }
        },
        runLottie() {
            return {
                async setup() {
                    let {default: anim} = await import('@/js/bepp.json');
                    let {default: lottie} = await import('lottie-web/build/player/lottie_light.min');
                    lottie.loadAnimation({
                        container: this.$el, // the dom element that will contain the animation
                        renderer: 'svg',
                        loop: false,
                        autoplay: true,
                        animationData: anim
                    });

                }
            }
        },
        initSkjema() {
            return {
                sender: false,
                response: {},
                // response: {
                //     success: true
                // },
                errors: [],
                async send() {
                    this.sender = true;
                    this.errors = [];
                    const data = new FormData(this.$el);
                    const action = this.$el.getAttribute('action') || '/';
                    let options = {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'X-CSRF-Token': this.csrf,
                            'X-Requested-With': 'XMLHttpRequest',
                        },
                        body: data
                    }

                    if (isExternalURL(action)) {
                        delete options.headers['X-CSRF-Token'];
                    }
                    // console.log(data, action);
                    await ofetch(action, options)
                        .then(json => {
                            console.log('success', json);
                            if (json.success) {
                                this.response = json;
                                this.response.success = true;
                            }
                            if (json.errors || json.error) {
                                this.errors = json.errors || [json.error];
                            }
                        }).catch((error) => {
                            console.error('Error:', error);
                            this.errors = [error];
                        }).finally(() => {
                            this.sender = false;
                        });
                },
            }
        },
        initMicrosoftSkjema(formId: String = null) {
            return {
                sender: false,
                response: {},
                success: false,
                samtykke: false,
                errors: [],
                async init() {
                    let script = document.createElement('script');
                    const skjema = this.$el;
                    if (!formId) return;

                    const mapping = await import('./dynamicsMapping');
                    const schema = mapping[skjema.id];
                    // console.log(schema)
                    script.onload = async () => {
                        const form = await d365mktformcapture.waitForElement('#' + skjema.id) // example: "#form1" as a selector for form with id="form1"

                        form.addEventListener("submit", async (e) => {
                            e.preventDefault();
                            if (!this.$validate.isComplete(skjema)) return;

                            const serializedForm = d365mktformcapture.serializeForm(form, schema);
                            // console.log(JSON.stringify(serializedForm)); // NOTE: enable for debugging
                            const payload = serializedForm.SerializedForm.build();

                            const captureConfig = {
                                FormId: formId,
                                FormApiUrl: "https://public-eur.mkt.dynamics.com/api/v1.0/orgs/8705f2c0-8176-45a9-89a6-3d13011878d0/landingpageforms"
                            }
                            this.sender = true;
                            const response = await d365mktformcapture.submitForm(captureConfig, payload).catch(e => {
                                this.errors = [e]
                            });

                            this.success = response.submissionStatus === 0;
                            this.sender = false;
                        }, true);
                    };

                    script.src = 'https://cxppusa1formui01cdnsa01-endpoint.azureedge.net/eur/FormCapture/FormCapture.bundle.js';
                    script.id = 'formCapture';
                    document.head.appendChild(script);
                },
                isSent() {
                    return this.success && this.errors.length === 0 && !this.sender;
                },
            }
        }
    }

    document.addEventListener('lazyloaded', (e) => {
        const el = e.target,
            parent = el.parentNode;

        if (parent.tagName == 'FIGURE' && !el.src?.includes(".svg")) {
            // console.log(parent, parent.tagName)
            if (el.tagName == 'video') {
                el.oncanplay = () => {
                    parent.classList.remove("theme-bg-inverted");
                };
            } else {
                setTimeout(() => {
                    parent.classList.remove("theme-bg-inverted");
                }, 300)
            }
        }
    });

    Alpine.start();
});
